import React from 'react'

const ReviewsCard = () => {
    return (
        <div className='review-card text-center py-5'>
            <img src="assets/images/cl-1.png" width={150} height={150} style={{ marginTop: '-100px' }} className='mx-auto rounded-circle' alt="" />
            <h3 className="text-primary f-family mt-3">Fox 5 DC</h3>
            <h5 className="f-family mt-3">Levareging AI to Prevent Active Shooter Events</h5>
        </div>
    )
}

export default ReviewsCard