import React from 'react'

const ContactCard = ({title, text, icon}) => {
  return (
    <div className='contact-card text-center p-5'>
        <div className='d-flex align-items-center justify-content-center'>
            <div className='icon'>
                <i className='material-icons'>{icon}</i>
            </div>
        </div>
        <h3 className='text-primary f-family mt-4'>{title}</h3>
        <h6>{text}</h6>
    </div>
  )
}

export default ContactCard