import React from 'react';
import UserLayout from '../components/layout/UserLayout'
import CoverageCard from '../components/section-components/CoverageCard'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';


const About = () => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };


  return (
    <UserLayout>

      <Helmet>
        <title>About | Flurrent</title>
      </Helmet>

      {/* -------------------- Main Section START -------------------- */}
      <section id='hero-section' style={{
        backgroundImage: `url('assets/images/Parking-Garage-Surveillance.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
        <div className='d-flex align-items-center px-4' style={{ height: '80vh', background: '#0000007a' }}>
          <div className='container pt-3'>
            <h3 className='f-family hero-heading text-white'>About</h3>
          </div>
        </div>
      </section>
      {/* -------------------- Main Section END -------------------- */}


      <section className='container sec-margin'>
        <h2 className='f-family text-center'>The Innovation that keeps you safe</h2>
        <div className="row mt-5">
          <div className="col-lg-6 text-center">
            <img src="assets/images/Commerial-Surveillance.png" className='w-100 rounded-3' alt="" />
            <img src="assets/images/Threat-Detection1.png" className='w-100 rounded-3 mt-3' alt="" />
          </div>
          <div className="col-lg-6 d-flex align-items-center">
            <div>
              <div className='about-sec-1 ps-4'>
                <p className='text-dark'>At Flurrent, we harness the power of artificial intelligence to revolutionize video
                  surveillance, providing real-time alerts to potential security threats. Our sophisticated
                  technology processes video data with remarkable speed and precision, turning traditional
                  surveillance into an intelligent, proactive defense mechanism.</p>
                <p className='text-dark'>One of the most challenging aspects of facility security is monitoring expansive areas,
                  tracking the movements of personnel, visitors, and vendors, and ensuring seamless
                  surveillance across multiple buildings or satellite locations. Flurrent addresses these
                  challenges with state-of-the-art AI algorithms and deep learning models that analyze
                  every video frame in real-time. Our system detects unusual activities, identifies
                  suspicious behavior, and swiftly alerts security personnel to ensure prompt action. With
                  Flurrent, you can achieve comprehensive security coverage and maintain a safe
                  environment for your operations.</p>
              </div>
              <button className='btn btn-primary mt-4'>Get In Touch</button>
            </div>
          </div>
        </div>
      </section>


      {/* -------------------- Mission Section END -------------------- */}
      <section className="bg-light py-5 sec-margin">
        <div className='container'>
          <div className="row">
            <div className="col-lg-6 my-3">
              <img src="assets/images/Construction-Surveillance.png" className='w-100' alt="" />
            </div>
            <div className="col-lg-6 my-3">
              <h1 className="text-primary f-family">Our Mission and Vision</h1>
              <p className='text-dark mt-4'>Our mission is to provide top-tier surveillance solutions that deter crime and protect
                valuable assets, ensuring safety and security for all our clients. We envision a world
                where advanced surveillance technology creates safer environments for businesses and
                communities, significantly reducing crime and enhancing peace of mind.</p>
            </div>
          </div>
        </div>
      </section>
      {/* -------------------- Mission Section END -------------------- */}


      {/* -------------------- Courege Section END -------------------- */}
      <section className='container sec-margin'>
        <h1 className='f-family text-center'>Press Coverage</h1>
        <div className="row mt-5">
          <div className="col-lg-6 my-4">
            <h3 className='f-family text-center'>Articles</h3>
            <div className='mt-4'>
              <Slider {...settings} className='text-center'>
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
              </Slider>
            </div>
          </div>
          <div className="col-lg-6 my-4">
            <h3 className='f-family text-center'>News Segments</h3>
            <div className='mt-4'>
              <Slider {...settings} className='text-center'>
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
                <CoverageCard />
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* -------------------- Courege Section END -------------------- */}

    </UserLayout >
  )
}

export default About