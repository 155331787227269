import React from 'react'
import UserLayout from '../components/layout/UserLayout'
import ContactCard from '../components/section-components/ContactCard'
import { Helmet } from 'react-helmet';


const Contact = () => {
  return (
    <UserLayout>

        <Helmet>
            <title>Contact | Flurrent</title>
        </Helmet>

      {/* -------------------- Main Section START -------------------- */}
      <section id='hero-section' style={{
        backgroundImage: `url('assets/images/contact-banner.jpg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
        <div className='d-flex align-items-center px-4' style={{ height: '80vh', background: '#0000007a' }}>
          <div className='container pt-3'>
            <h3 className='f-family hero-heading text-white'>Contact</h3>
          </div>
        </div>
      </section>
      {/* -------------------- Main Section END -------------------- */}

      {/* -------------------- Contact Cards Section END -------------------- */}
      <section className='container sec-margin'>
        <h3 className='text-primary f-family text-center'>Get In Touch</h3>
        <h1 className='f-family text-center'>We Look Forward To Hearing From You</h1>
        <div className="row mt-5">
          <div className="col-lg-4 my-3">
            <ContactCard title={'Phone Number'} text={'+1 301-747-3311'} icon={'phone'} />
          </div>
          <div className="col-lg-4 my-3">
            <ContactCard title={'Email Address'} text={'info@flurrent.com'} icon={'email'} />
          </div>
          <div className="col-lg-4 my-3">
            <ContactCard title={'Address'} text={'6710 Oxon Hill Road, Suite 325, Oxon Hill, MD 20745'} icon={'location_on'} />
          </div>
        </div>
      </section>
      {/* -------------------- Contact Cards Section END -------------------- */}

      <section className='container sec-margin'>
        <div className="row">
          <div className="col-lg-5 my-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.874576295997!2d-74.0060151845921!3d40.71277587933011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a19d68aa927%3A0xe24e1e13b2fd789e!2sNew%20York%20City%2C%20NY!5e0!3m2!1sen!2sus!4v1605235604036!5m2!1sen!2sus"
              width="100%"
              height="630"
              allowFullScreen=""
              loading="lazy">
            </iframe>
          </div>
          <div className="col-lg-7 my-3">
            <div className='info-form p-4'>
              <h3 className='f-family text-center'>Request More Information</h3>
              <div className="row p-4">
                <div className="col-md-6 ps-0 pe-0 pe-2 my-2 py-1">
                  <input type="text" name="" className='form-control' placeholder='Your Name' id="" />
                </div>
                <div className="col-md-6 ps-0 pe-0 my-2 ps-2 py-1">
                  <input type="text" name="" className='form-control' placeholder='Your Email' id="" />
                </div>
                <div className="col-md-6 ps-0 pe-0 my-2 pe-2 py-1">
                  <input type="text" name="" className='form-control' placeholder='Phone Number' id="" />
                </div>
                <div className="col-md-6 ps-0 pe-0 my-2 ps-2 py-1">
                  <input type="text" name="" className='form-control' placeholder='Details' id="" />
                </div>
                <div className="col-md-12 ps-0 pe-0 my-2 py-1">
                  <input type="text" name="" className='form-control' placeholder='Subject' id="" />
                </div>
                <div className="col-md-12 ps-0 pe-0 my-2 py-1">
                  <textarea name="" className='form-control' placeholder='Message' rows={4} id=""></textarea>
                </div>
                <button className='btn btn-primary w-100 mt-3 submit-btn'>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </UserLayout>
  )
}

export default Contact