import React, { useState, useEffect } from 'react';
import UserLayout from '../components/layout/UserLayout'
import VideoModal from '../components/section-components/VideoModal';
import ReviewsCard from '../components/section-components/ReviewsCard';
import FaqCard from '../components/section-components/FaqCard';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { Helmet } from 'react-helmet';


const Index = () => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    const heroImages = ['Watching-Surveillance1.png', 'Watching-Surveillance2.png', 'Watching-Surveillance3.png'];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % heroImages.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [heroImages.length]);


    return (
        <UserLayout>


            <Helmet>
                <title>Home | Flurrent</title>
            </Helmet>

            {/* -------------------- Main hero Section START -------------------- */}
            <section id='hero-section' style={{
                backgroundImage: `url('assets/images/${heroImages[currentImageIndex]}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative',
                overflow: 'hidden'
            }}>
                <div className='d-flex align-items-center px-4' style={{ height: '80vh', background: '#0000007a' }}>
                    <div className='container pt-3 hero-header'>
                        <h3 className='f-family hero-heading text-white'>Flurrent: Vigilance Redefined<br /> Guarding Your Assets, Around the Clock                        </h3>
                        <p className='hero-para mt-4 text-white'>Explore Flurrent’s cutting-edge AI-powered 24/7 video surveillance tailored for the
                            modern world. We offer innovative solutions that provide unparalleled safety and
                            convenience for your business. Stay ahead with artificial intelligence monitoring,
                            real-time alerts, and seamless control from anywhere. Experience the ultimate peace of
                            mind with our advanced technology designed to protect what matters most to you.</p>
                        <button className="btn btn-primary mt-4" type="button">Get in Touch</button>
                    </div>
                </div>
            </section>
            {/* -------------------- Main hero Section END -------------------- */}


            {/* -------------------- About us Section START -------------------- */}
            <section className='container sec-margin'>
                <div className="row mx-0 g-4">
                    <div className="col-lg-7 my-3">
                        <div className="row">
                            <div className="col-md-6 my-2 p-2">
                                <div className='bg-danger'>
                                    <h5 className='f-family text-white p-3'>We’re Trusted by <br /> Customers Across <br /> Industries</h5>
                                </div>
                                <img src="assets/images/Surveillance-Installation.png" className='w-100 mt-2' alt="" />
                                <img src="assets/images/Retail-Surveillance.png" className='w-100 mt-3' alt="" />
                            </div>
                            <div className="col-md-6 my-2 p-2">
                                <img src="assets/images/Surveillance-Camera2.png" className='w-100' alt="" />
                                <img src="assets/images/camera1.jpg" className='w-100 mt-3' alt="" />
                                {/* <div className='d-flex justify-content-center align-items-center mt-5 pt-5'>
                                    <div className="play-icon" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        <i className="material-icons text-white fs-1">play_arrow</i>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 my-3 d-flex align-items-center">
                        <div>
                            <h5 className='text-primary f-family'>About Us</h5>
                            <h2 className='f-family'>Your Security is <br /> Our Priority</h2>
                            <p className=''>Flurrent is dedicated to revolutionizing security through relentless vigilance and
                                innovative surveillance solutions. We have customized our services to cater to larger,
                                more complex environments. Our team consists of security experts and technology
                                enthusiasts committed to providing unparalleled protection for your property.
                            </p>
                            <button className='btn btn-outline-primary mt-4'>Read More</button>
                        </div>
                    </div>
                </div>
                <VideoModal />
            </section>
            {/* -------------------- About us Section END -------------------- */}


            {/* -------------------- Service Section START -------------------- */}
            <section className='container sec-margin'>
                <h3 className='text-primary text-center f-family'>Our Services</h3>
                <div className="row mx-0 mt-5">
                    <div className="col-lg-6">
                        <div className='home-service-card bg-light'>
                            <img src="assets/images/Surveillance-Camera.png" height={360} className='w-100 home-service-img' alt="" />
                            <div className='p-3'>
                                <h6 className='text-primary f-family'>Comprehensive Surveillance Solutions</h6>
                                <p>We offer comprehensive surveillance services designed to meet the unique needs of
                                    various sectors. From monitoring construction sites to securing parking garages, our
                                    systems are equipped with high-resolution cameras, night vision capabilities, and
                                    real-time alert mechanisms. Our services are aimed at preventing theft, vandalism, and
                                    unauthorized access, ensuring your premises remain secure at all times.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className='home-service-card bg-light'>
                            <img src="assets/images/Surveillance-Camera2.png" height={360} className='w-100 home-service-img' alt="" />
                            <div className='p-3'>
                                <h6 className='text-primary f-family'>Customizable Security Packages
                                </h6>
                                <p>At Flurrent, we understand that each client has unique security requirements. That's why
                                    we offer customizable security packages tailored to your specific needs. Whether you
                                    need surveillance for a corporate building, retail space, new neighborhood, commercial
                                    establishment, car lot, or warehouse, our team will work with you to design and
                                    implement a solution that provides optimal protection. Our flexible packages ensure you
                                    get the coverage you need without paying for unnecessary features.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-center mt-5'>
                    <button className='btn btn-primary '>Learn More</button>
                </div>
            </section>
            {/* -------------------- Service Section END -------------------- */}


            {/* -------------------- Why choose Section START -------------------- */}
            <section className='sec-margin' style={{
                backgroundImage: "url('/assets/images/Parking-Garage-Surveillance.png')",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <div className='d-flex align-items-center py-5' id='whiy-section'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 my-4">
                                <img src="assets/images/Threat-Detection4.png" className='w-100' alt="" />
                            </div>
                            <div className="col-lg-6 my-4 text-white ">
                                <h1 className='f-family'>Why Choose Flurrent</h1>
                                <p>Choosing Flurrent means opting for peace of mind. Our advanced surveillance
                                    technology, combined with our focus on high-risk nighttime hours, ensures that your
                                    property is under constant watch. We pride ourselves on our quick response times,
                                    user-friendly systems, and the ability to tailor our services to fit the specific needs of our
                                    clients. With Flurrent, you can trust that your assets are in safe hands.</p>
                                <button className='btn btn-primary mt-3'>Book a Demo</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* -------------------- Why choose Section END -------------------- */}


            <section className='sec-margin py-5' style={{
                backgroundImage: "url('/assets/images/review-back.png')",
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-8">
                            <Slider {...settings} className='text-center'>
                                <div className='py-6'>
                                    <ReviewsCard />
                                </div>
                                <div className='py-6'>
                                    <ReviewsCard />
                                </div>
                                <div className='py-6'>
                                    <ReviewsCard />
                                </div>
                                <div className='py-6'>
                                    <ReviewsCard />
                                </div>
                            </Slider>
                        </div>
                        <div className="col-lg-4">
                            <h4 className='text-primary f-family'>News Coverage</h4>
                            <h1 className='f-family'>Stories About Our Technology & Team</h1>
                        </div>
                    </div>
                </div>
            </section>


            <section className='sec-margin text-center container'>
                <h1 className="text-center text-primary f-family">Frequently Asked Questions</h1>
                <div className='mt-5'>
                    <FaqCard />
                </div>
                <button className='btn btn-primary mt-4'>Learn More</button>
            </section>

            {/* -------------------- Contact US Section START -------------------- */}
            <section className='position-relative sec-margin'>
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-6">
                            <h5 className='text-primary'>Contact Us</h5>
                            <h2 className='f-family'>Get In Touch</h2>
                            <p className='mt-2'>Please contact us via one of the communication mediums below.</p>
                            <div className="row">
                                <div className="col-lg-6 my-3 d-flex align-items-start">
                                    <div className="contact-icon d-flex align-items-center justify-content-center">
                                        <i className='material-icons fs-3 text-white'>phone</i>
                                    </div>
                                    <div className='ms-2 mt-2'>
                                        <h5 className='f-family text-primary p-0 m-0'>Phone Number</h5>
                                        <p className='text-dark  p-0 m-0 mt-3'>+1 301-747-3311</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 my-3 d-flex align-items-start">
                                    <div className="contact-icon d-flex align-items-center justify-content-center">
                                        <i className='material-icons fs-3 text-white'>email</i>
                                    </div>
                                    <div className='ms-2 mt-2'>
                                        <h5 className='f-family text-primary p-0 m-0'>Email Address</h5>
                                        <p className='text-dark p-0 m-0 mt-3'>info@flurrent.com</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 my-3 d-flex align-items-start">
                                    <div className="contact-icon d-flex align-items-center justify-content-center">
                                        <i className='material-icons fs-3 text-white'>language</i>
                                    </div>
                                    <div className='ms-2 mt-2'>
                                        <h5 className='f-family text-primary p-0 m-0'>Website</h5>
                                        <p className='text-dark  p-0 m-0 mt-3'> www.flurrent.com</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 my-3 d-flex align-items-start">
                                    <div className="contact-icon d-flex align-items-center justify-content-center">
                                        <i className='material-icons fs-3 text-white'>location_on</i>
                                    </div>
                                    <div className='ms-2 mt-2'>
                                        <h5 className='f-family text-primary p-0 m-0'>Address</h5>
                                        <p className='text-dark p-0 m-0 mt-3'>6710 Oxon Hill Road, <br /> Suite 325, Oxon Hill, <br /> MD 20745</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="row p-4">
                                <div className="col-md-6 ps-0 pe-0 pe-2 my-2 py-1">
                                    <input type="text" name="" className='form-control' placeholder='Your Name' id="" />
                                </div>
                                <div className="col-md-6 ps-0 pe-0 my-2 ps-2 py-1">
                                    <input type="text" name="" className='form-control' placeholder='Your Email' id="" />
                                </div>
                                <div className="col-md-12 ps-0 pe-0 my-2 py-1">
                                    <input type="text" name="" className='form-control' placeholder='Phone Number' id="" />
                                </div>
                                <div className="col-md-12 ps-0 pe-0 my-2 py-1">
                                    <textarea name="" className='form-control' placeholder='Message' rows={4} id=""></textarea>
                                </div>
                                <button className='btn btn-primary w-100 mt-3 submit-btn'>Submit</button>
                            </div>
                            <img src="assets/images/contact-camera.png" className='position-absolute' style={{
                                right: '0',
                                top: '0',
                                zIndex: '-1',
                                opacity: '0.5'

                            }} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* -------------------- Contact US Section END -------------------- */}


        </UserLayout>
    )
}

export default Index