import React from 'react'

const ServiceCard = ({image, desc, title, icon}) => {
  return (
    <div className='service-card'>
        <img src={`assets/images/${image}`} className='w-100' alt="" />
        <div className='px-4'>
            <img src={`assets/images/${icon}`} width={85} style={{marginTop: '-35px'}} alt="" />
        </div>
        <div className='p-3'>
            <h4 className='f-family'>{title}</h4>
            <p>{desc}</p>
        </div>
    </div>
  )
}

export default ServiceCard