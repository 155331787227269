import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const UserLayout = ({ children }) => {

    let route = useLocation();

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-white bg-white px-3">
                <div className="container">
                    <Link className="navbar-brand" to={'/'}>
                        <img src="assets/images/logo.png" width={150} alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className={`nav-link ${route.pathname === '/' ? 'active-link' : ''} mx-3 f-family`} to={'/'}>Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${route.pathname === '/about' ? 'active-link' : ''} f-family mx-3`} to={'/about'}>About</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${route.pathname === '/services' ? 'active-link' : ''} f-family mx-3`} to={'/services'}>Service</Link>
                            </li>
                            <li className="nav-item">
                                <Link className={`nav-link ${route.pathname === '/contact' ? 'active-link' : ''} f-family mx-3`} to={'/contact'}>Contact</Link>
                            </li>
                        </ul>
                        <div className="d-flex">
                            <button className="btn btn-primary">Login</button>
                            <button className="btn btn-primary ms-3">Book a Demo</button>
                        </div>
                    </div>
                </div>
            </nav>
            <div>
                {children}
            </div>

            <footer className='bg-dark mt-5'>
                <div className="container">
                    <div className="row py-5">
                        <div className="col-lg-3 my-3 text-center">
                            <Link className="navbar-brand" to={'/'}>
                                <img src="assets/images/footer_logo.png" className='rounded-2' width={150} alt="" />
                            </Link>
                            <h6 className='text-white f-family mt-4'>Securing a Digital Future</h6>
                            <div className='d-flex align-items-center justify-content-center mt-3'>
                                <a href='#' className="social-icon text-decoration-none me-2">
                                    <img src="assets/images/facebook.jpg" className=' rounded-circle' width={22} alt="" />
                                </a>
                                <a href='#' className="social-icon text-decoration-none">
                                    <img src="assets/images/twitter.jpg" className=' rounded-circle' width={25} alt="" />
                                </a>
                                <a href='#' className="social-icon text-decoration-none mx-2">
                                    <img src="assets/images/in.jpg" className=' rounded-circle' width={25} alt="" />
                                </a>\
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <h4 className='text-white f-family'>Quick Links</h4>
                            <div className='mt-4'>
                                <Link to={'/'} className='d-block text-decoration-none text-white my-2'>Home</Link>
                                <Link to={'/about'} className='d-block text-decoration-none text-white my-2'>About</Link>
                                <Link to={'/services'} className='d-block text-decoration-none text-white my-2'>Services</Link>
                                <Link to={'/'} className='d-block text-decoration-none text-white my-2'>FAQ's</Link>
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <h4 className='text-white f-family'>Services</h4>
                            <div className='mt-4'>
                                <Link to={'/'} className='d-block text-decoration-none text-white my-2'>Security Cameras</Link>
                                <Link to={'/'} className='d-block text-decoration-none text-white my-2'>24/7 Services</Link>
                            </div>
                        </div>
                        <div className="col-lg-3 my-3">
                            <h4 className='text-white f-family'>Contact</h4>
                            <div className='mt-4'>
                                <div className='d-flex align-items-center text-white'>
                                    <i className='material-icons'>phone</i>
                                    <p className='ms-2 p-0 m-0'>(301) 747-3311</p>
                                </div>
                                <div className='d-flex align-items-center text-white my-3'>
                                    <i className='material-icons'>email</i>
                                    <p className='ms-2 p-0 m-0'>info@flurrent.com</p>
                                </div>
                                <div className='d-flex align-items-center text-white'>
                                    <i className='material-icons'>location_on</i>
                                    <p className='ms-2 p-0 m-0'>6710 Oxon Hill Road, Suite 325, Oxon
                                        Hill, MD 20745</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="bg-secondary py-2">
                <div className="container d-flex align-items-center justify-content-between flex-wrap">
                    <h6 className='p-0 m-0 text-white my-2'>© 2024 Flurrent | All Rights Reserved | Privacy Policy | Terms and Conditions</h6>
                    <a href="#" className='text-white my-2'>
                        <i className='material-icons border border-3 text-white fs-2 '>keyboard_arrow_up</i>
                    </a>
                </div>
            </div>
        </>
    )
}

export default UserLayout