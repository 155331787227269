import React from 'react'
import UserLayout from '../components/layout/UserLayout'
import ServiceCard from '../components/section-components/ServiceCard'
import { Helmet } from 'react-helmet';


const Services = () => {
  return (
    <UserLayout>

      <Helmet>
        <title>Services | Flurrent</title>
      </Helmet>

      {/* -------------------- Main Section START -------------------- */}
      <section id='hero-section' style={{
        backgroundImage: `url('assets/images/Commerial-Surveillance.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
        <div className='d-flex align-items-center px-4' style={{ height: '80vh', background: '#0000007a' }}>
          <div className='container pt-3'>
            <h3 className='f-family hero-heading text-white'>Services</h3>
          </div>
        </div>
      </section>
      {/* -------------------- Main Section END -------------------- */}


      {/* -------------------- Services Section END -------------------- */}
      <section className='container sec-margin'>
        <div className='text-center'>
          <h4 className='text-primary f-family '>Our Services</h4>
          <h2 className='f-family'>Welcome to Flurrent</h2>
          <h5 className='f-family mt-3'>Bespoke Security Solutions for Your Unique Needs</h5>
          <p className='mt-4'>At Flurrent, we understand that every business has distinct security requirements. Our
            team of experienced professionals is committed to delivering customized surveillance
            solutions tailored to your specific needs. With Flurrent, you receive comprehensive and
            adaptable security solutions designed to protect what matters most to you.</p>
        </div>
      </section>
      <section className='container mt-5'>
        <div className="row">
          <div className="col-lg-4 my-3">
            <ServiceCard
              image="Retail.png"
              icon="cctv-1-1.png"
              title="Retail"
              desc="At Flurrent, we specialize in providing comprehensive security solutions tailored
                    specifically for retail environments. Our expert team understands the unique challenges
                    faced by retailers, from theft prevention to ensuring the safety of customers and staff.
                    With our state-of-the-art surveillance systems and real-time monitoring capabilities, we
                    help you create a secure shopping environment, free from threats and disruptions. Partner
                    with Flurrent to safeguard your retail space and protect your assets."
            />
          </div>
          <div className="col-lg-4 my-3">
            <ServiceCard
              image="Construction.png"
              icon="spy-camera-1-1.png"
              title="Construction"
              desc="Flurrent offers advanced security solutions designed to protect construction sites around
                    the clock. With our high-resolution cameras, night vision capabilities, and real-time alert
                    systems, we help you prevent theft, vandalism, and unauthorized access to your valuable
                    equipment and materials. Our customized surveillance packages are tailored to fit the
                    specific needs of construction projects, providing peace of mind for contractors and
                    project managers alike. Choose Flurrent for comprehensive construction site security
                    solutions."
            />
          </div>
          <div className="col-lg-4 my-3">
            <ServiceCard
              image="Commercial.png"
              icon="router-1-1.png"
              title="Commercial"
              desc="Protecting commercial properties is essential for maintaining a safe and secure business
                    environment. At Flurrent, we specialize in enhancing the security of commercial
                    buildings, including office complexes, to ensure a safe workplace for employees and
                    visitors. Our tailored security solutions address the unique challenges faced by
                    commercial spaces, providing peace of mind and maintaining productivity. Partner with
                    Flurrent to safeguard your commercial property and the people who occupy it.
"
            />
          </div>
          <div className="col-lg-4 my-3">
            <ServiceCard
              image="Parking-Garage.png"
              icon="cloud-1-1.png"
              title="Parking Garage"
              desc="Flurrent understands the importance of security in parking garages and car lots. Our
                    advanced surveillance systems are specifically designed to monitor these areas and deter
                    criminal activities such as theft and vandalism. With features like high-resolution cameras
                    and real-time alerts, we help you maintain a secure environment for vehicles and ensure
                    the safety of your customers and employees. Choose Flurrent for comprehensive parking
                    garage security solutions.
                    "
            />
          </div>
          <div className="col-lg-4 my-3">
            <ServiceCard
              image="Warehouse.png"
              icon="cyber-security-1-1.png"
              title="Warehouse"
              desc="Warehouse security is critical for protecting valuable inventory and assets. Flurrent offers
tailored security solutions for warehouses, including high-tech surveillance systems and
access control measures. Our advanced technology detects and alerts you to potential
threats in real-time, allowing you to take immediate action and prevent losses. Partner
with Flurrent to secure your warehouse and maintain efficient operations."
            />
          </div>
          {/* <div className="col-lg-4 my-3">
            <ServiceCard
              image="sv6.png"
              icon="shield-1-1.png"
              title="Education"
              desc="Building a Safer Business Environment. At PerVista, we specialize in enhancing the security of commercial locations, including office buildings, to ensure a safe, weapon-free workplace. Our expertly tailored security solutions are designed to address the specific challenges faced by commercial spaces, providing peace of mind for both employees and visitors. With PerVista, you can maintain a productive, secure, and welcoming business environment, free from the threat of weapons. Partner with us to safeguard your commercial location and the people who make it thrive"
            />
          </div> */}
        </div>
      </section>
      {/* -------------------- Services Section END -------------------- */}


      {/* -------------------- Features Section END -------------------- */}
      <section className='sec-margin' style={{
        backgroundImage: `url('assets/images/Nighttime-Surveillance.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
        <div className="container py-5">
          <div className="row">
         
            <div className="col-lg-7">
              <h4 className='text-primary f-family '>Features</h4>
              <h2 className='f-family text-white'>Opting For Security And <br /> Peace Of Mind</h2>
              <div className="row">
                <div className="col-md-6 my-3">
                  <div className='d-flex align-items-start feature-card p-3 bg-white'>
                    <img src="assets/images/spy-camera-1-1.png" width={50} alt="" />
                    <h6 className='f-family ms-3'>24/7 Monitoring: Continuous surveillance to ensure round-the-clock protection.
                      Immediate notifications in case of suspicious activities or breaches.</h6>
                  </div>
                </div>
                <div className="col-md-6 my-3">
                  <div className='d-flex align-items-start feature-card p-3 bg-white'>
                    <img src="assets/images/shield-1-1.png" width={50} alt="" />
                    <h6 className='f-family ms-3'>Night Vision: High-quality night vision cameras for clear images in low light
                      conditions</h6>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className='d-flex align-items-start feature-card p-3 bg-white'>
                    <img src="assets/images/global-1.png" width={50} alt="" />
                    <h6 className='f-family ms-3'>High-Resolution Cameras: Crystal clear footage for accurate monitoring and
                      evidence collection.</h6>
                  </div>
                </div>
                <div className="col-md-6 mt-3">
                  <div className='d-flex align-items-start feature-card p-3 bg-white'>
                    <img src="assets/images/database-1-1.png" width={50} alt="" />
                    <h6 className='f-family ms-3'>User-Friendly Interface: Easy-to-use systems for seamless operation and
                      monitoring</h6>
                  </div>
                </div>
              </div>
              <div className='grediant-primary d-flex align-items-center p-3 justify-content-between flex-wrap'>
                <h5 className='f-family text-white'>Want To Invest In Our State-Of-The <br /> Art Video Security Systems?</h5>
                <button className='btn btn-primary'>Book a Demo</button>
              </div>
            </div>
            <div className="col-lg-5"></div>
          </div>
        </div>
      </section>
      {/* -------------------- Features Section END -------------------- */}

      <section className='container sec-margin'>
        <div className='text-center'>
          <h4 className='text-primary f-family '>How We Work</h4>
          <h2 className='f-family'>Tailored Security Solutions For Every Client</h2>
          <p className='mt-4'>At Flurrent, we understand that each client has unique security needs. We believe in
            prioritizing a deep understanding of your specific requirements to craft personalized
            security solutions that exceed expectations. Through close collaboration, we meticulously
            tailor each implementation to align perfectly with your individual challenges and
            environment. Choose us for a personalized security approach that provides a safer, more
            secure environment tailored specifically to you.
          </p>
        </div>
        <div className="row g-5 mt-5">
          <div className="col-lg-4 my-3">
            <div className='text-center'>
              <h1 className='count f-family'>01</h1>
              <h5 className='f-family text-primary mt-3'>
                Understanding Your Needs
              </h5>
              <p className='text-dark mt-4'>Our process begins with a thorough discussion to understand your specialized security
                requirements. We believe in creating solutions as unique as our clients, which is why
                understanding your needs is paramount. Through collaboration, we ensure that the
                security strategies we implement are perfectly aligned with your expectations and
                challenges. Let us tailor a security solution that fits your unique situation, providing the
                protection and peace of mind you deserve.</p>
            </div>
          </div>
          <div className="col-lg-4 my-3">
            <div className='text-center'>
              <h1 className='count f-family'>02</h1>
              <h5 className='f-family text-primary mt-3'>
                Strategic Surveillance Installation
              </h5>
              <p className='text-dark mt-4'>Next, we strategically install surveillance cameras at key exterior and interior locations.
                Our expert team evaluates your space to identify critical areas, ensuring comprehensive
                coverage and optimal vantage points for threat detection. Each camera is strategically
                placed to serve a specific purpose, enhancing the overall security of your environment.
                Trust us to equip your premises with vigilant surveillance, safeguarding against risks
                while preserving the integrity of your space.
              </p>
            </div>
          </div>
          <div className="col-lg-4 my-3">
            <div className='text-center'>
              <h1 className='count f-family'>03</h1>
              <h5 className='f-family text-primary mt-3'>
                Proactive Maintenance and Optimization
              </h5>
              <p className='text-dark mt-4'>Our partnership doesn't end with installation. We emphasize proactive maintenance and
                continuous optimization of our security systems. This includes refining our technology to
                minimize false positives and optimizing surveillance camera placements to adapt to
                evolving security needs. By collaborating closely with you, we enhance detection
                capabilities and prevent incidents before they occur, ensuring your environment remains
                safe and secure.
              </p>
            </div>
          </div>
        </div>
      </section>

    </UserLayout>
  )
}

export default Services