import React from 'react'

const VideoModal = () => {
    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered p-0">
                    <div className="modal-content p-0">
                        {/* <div className="modal-header" style={{background: 'black'}}>
                        </div> */}
                        <div>
                            <iframe
                                width="100%"
                                height="500"
                                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VideoModal