import React from 'react'

const CoverageCard = () => {
    return (
        <div className='mx-2 bg-light courage-card d-flex align-items-center justify-content-center'>
            <div>
                <img src="assets/images/cl-1.png" width={150} height={150} className='mx-auto rounded-circle' alt="" />
                <h2 className='f-family text-primary mt-4'>Fortune Maxine</h2>
                <a href="#" className='text-dark text-decoration-none f-family'>Read More</a>
            </div>
        </div>
    )
}

export default CoverageCard