import React from 'react'

const FaqCard = () => {
    return (
        <div className="accordion text-start" id="accordionExample">
            <div className="accordion-item my-3 border-top">
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Can i receive notificaiton or alter when the system detects a security event?
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <p>Yes, F offers the capability to send notifications or alerts when a security event is detected. These notifications can be sent to your smartphone, tablet, computer, or even via email or text message.</p>
                    </div>
                </div>
            </div>
            <div className="accordion-item my-3 border-top">
                <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Are video security systems vulnarable tp hacking or unauthorized access?
                    </button>
                </h2>
                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <p>Overlapping camera coverage can help ensure that no area is left unmonitored. By positioning cameras strategically, you create overlapping fields of view, minimizing blind spots and increasing the chances of capturing critical events from multiple angles</p>                    
                        </div>
                </div>
            </div>
            <div className="accordion-item my-3 border-top">
                <h2 className="accordion-header" id="headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Can i access the video footage from multiple devices (e.g., smartpphones tablets, computers )?
                    </button>
                </h2>
                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div className="accordion-body">
                        <p>Yes, video footage is accessible from any device with an internet connection.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqCard